define(['siteObj', '$console'], function(siteObj, $console) {
  
  var loadPostSocial = function() {
    var localpath = 0;
    var extpath = ('https:' === document.location.protocol ? 'https://s' : 'http://i') + '.po.st/static/v4/post-widget.js';

    var publisherKey = (siteObj && siteObj.postSocialWidgetID) ? siteObj.postSocialWidgetID : 0;
    if (publisherKey) {
      var param = '#publisherKey=' + publisherKey;
      var filePath = (localpath) ? localpath + param : extpath + param;
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      //s.widget = "post-widget";
      s.src = filePath;
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
      $console.log('post social loaded', publisherKey, filePath);
    } else {
      $console.log('PO.ST Widget publisherKey/ID Missing, Please Add in TCM SiteConfig');
    }
  };

  loadPostSocial();
  return loadPostSocial;
});

